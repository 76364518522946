<template>
<CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="5">
                    <h4>Merchant</h4>
              </CCol>
              <CCol md="5">
                   <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />
              </CCol>
                <CCol md="2"> 
                  <b-dropdown variant="primary" text="Action" size="md">
                    <!-- <router-link :to='"/merchant/create"' tag="b-dropdown-item" > New Merchant </router-link> -->
                    <router-link :to='"/merchant/img/default"' tag="b-dropdown-item" > Default Image </router-link>
                  </b-dropdown>
                </CCol>             
          </CRow>
          <CRow>
                <CCol col="12" class="text-left">                   
                  <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                </CCol> 
          </CRow>
        </CCardHeader><br/>
        <CCardBody>
          <div class="table-responsive table">
	        <b-table striped hover  :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter">	

          <template v-slot:cell(merchantIdentify.userId)="{ item }"> 
              {{ item.merchantIdentify.userId | IdSubstr }}
          </template>   

          <template v-slot:cell(mobileNumber)="{ item }"> 
              {{ item.mobileNumber.countryCode }} - {{ item.mobileNumber.number }}
          </template>      

	        <template v-slot:cell(createDate)="{ item }"> 
	              {{ item.createDate | dateSubstr }}
	        </template> 

          <template v-slot:cell(actions)="{ item }">
          <b-dropdown variant="info" text="Actions" size="md">
            <router-link :to='"/merchant/" + item.merchantIdentify.userId' tag="b-dropdown-item" > Detail </router-link>
          </b-dropdown>
          </template>
	        </b-table>
          </div>

          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>  

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import AuthService from '@/api/AuthService.js';
import MerchantService from '@/api/MerchantService.js';

 export default {
    name: "users",
    data() {
      return {
        items:[],             
        fields: [        	  
          {
            key: 'merchantIdentify.userId',
            label : 'Merchant ID',
            sortable: true
          }, 
          {
            key: 'displayName',
            label : 'Display Name',
            sortable: true
          },
          {
            key: 'userName',
            label : 'User Name',
            sortable: true
          },  
          {
            name : 'firstName',
            key: 'firstName',
            label : 'First Name',
            sortable: true
          }, 
          {
            key: 'lastName',
            label : 'Last Name',
            sortable: true
          },          
          {
            key: 'emailAddress',
            label : 'Email',
            sortable: true
          },
          {
            key: 'mobileNumber',
            label : 'Mobile Number',
            sortable: true
          },    
          {
            key: 'merchantStatus',
            label : 'Status',
            sortable: true
          },
          {            
            key: 'actions',
            label: 'Actions'         
          } 
        ],       
        currentPage: 1,
        perPage: 10,
        filter: null,
        cbactive:false,
        seen:false,
        msg :'',
        color:''
      };
    },
    directives: {
        dateSubstr: {
            update(el) {
                var temp = el.value.toString().split(".");
                el.value =  temp[0].replace("T"," ");
            },
        },        
    },

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },

        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)         
        },

        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },

        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10) + '...'            
        }


      },

    created () {  

       this.getMerchantList();   

    },
    methods: { 

      stringToBoolean: function(string){
          switch(String(string).toLowerCase().trim()){
              case "true": case "yes": case "1": return true;
              case "false": case "no": case "0": case null: return false;
              default: return Boolean(string);
          }
      },

      getMerchantList: function() {
        MerchantService.getMerchantList().then(resp => {  //console.log(resp);     
            this.items = resp; 
        }, error => {
          this.loading = false;
        });
      },

    },
    mounted() { 
       
    },   
 }; 

</script>
<style>
    .table{ overflow-y:scroll; }
</style>