import axios from 'axios';

const url = process.env.VUE_APP_ROOT_API ; 

export default {

  getMerchantList(){ 
    return axios.post(url + '/customer-care-portal/merchant/list/all/0/1000').then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  getMerchantById(merchantId){ //console.log(params);
    return axios.get(url + '/customer-care-portal/merchant/merchantIdentify/'+merchantId).then(response =>response.data.payload)
    .catch((error) => { return error.response }); 
  },

  getDetailMerchant(merchantId){ //console.log(params);
    return axios.get(url + '/opay-api/merchant/'+merchantId).then(response =>response.data.payload)
    .catch((error) => { return error.response }); 
  },

  createNewMerchant(walletGrpNum,params){ //console.log(params);
    return axios.post(url + '/opay-api/merchant/create/'+walletGrpNum, params).then(response =>response.data)
    .catch((error) => { return error.response }); 
  }, 


  updateMerchant(merchantId,params){ //console.log(params);
    return axios.post(url + '/opay-api/merchant/update/'+merchantId, params).then(response =>response.data)
    .catch((error) => { return error.response }); 
  }, 

  uploadImageMerchant(merchantId,formData){
    return axios.post(url + '/opay-api/merchant/resource-upload/image/'+merchantId, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    }).then(response =>response.data)
    .catch((error) => { return error.response }); 
  },

  uploadLogoMerchant(merchantId,formData){
    return axios.post(url + '/opay-api/merchant/resource-upload/logo/'+merchantId, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    }).then(response =>response.data)
    .catch((error) => { return error.response }); 
  },

  downloadResource(key,type){
    return axios.get(url + '/opay-api/merchant/resource-download/'+key+'/'+type,{responseType: 'arraybuffer'}).then(response =>Buffer.from(response.data, 'binary').toString('base64'))
    .catch((error) => { return error.response }); 
  },

  downloadDefaultImg(key){
    return axios.get(url + '/opay-api/p2m/buynow/resource-download/'+key,{responseType: 'arraybuffer'}).then(response =>Buffer.from(response.data, 'binary').toString('base64'))
    .catch((error) => { return error.response }); 
  },

  uploadDefaultImageMerchant(formData){
    return axios.post(url + '/opay-api/p2m/buynow/resource-upload', formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    }).then(response =>response.data)
    .catch((error) => { return error.response }); 
  },

};